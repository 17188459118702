<template>
  <div class="nav_wrap">
    <NavBar :title="title" :border="border" :fixed="fixed" :z-index="zIndex" :placeholder="fixed">
      <Icon v-if="leftArrow" slot="left" name="arrow-left" color="var(--light)"
        @click="$router.back()" size="20" />
      <div slot="left" v-else>
        <slot name="left"></slot>
      </div>
      <div slot="right">
        <slot name="right"></slot>
      </div>
    </NavBar>
  </div>
</template>

<script>
import { NavBar, Icon } from 'vant';
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    leftArrow: {
      type: Boolean,
      default: true
    },
    border: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    zIndex: {
      type: Number,
      default: 1
    },
    background: {
      type: String,
      default: ""
    }
  },
  components: {
    NavBar, Icon
  },
  data() { return {} },
  methods: {},
  created() { }
}
</script>

<style lang="less" scoped>
.nav_wrap {
  /deep/ .van-nav-bar {
    background-color: var(--bg);
  }

  .van-nav-bar {
    /deep/ .van-nav-bar__content {
      background-color: var(--bg);
    }

  }

  /deep/.van-nav-bar__title {
    color: var(--light);
  }
}
</style>